<template>
  <v-footer
    height="auto"
    color="primary"
  >
    <v-container fluid pa-0 pt-5 max-width-1400 ma-auto px-4>
      <v-layout row wrap>
        <v-flex xs12 md4 class="mb-5">
          <v-img
            height="25px"
            position="left center"
            contain
            class="clickable mr-auto"
            :src="images.logo"
            :lazy-src="images.logo"
            @click="changeRoute('/', 'goto_landing')"
          ></v-img>

          <v-layout row wrap py-3>
            <v-flex xs12>
              <p class="font-14 mb-2 white--text graphik-medium">{{ $ml.get('footer_title_gorila') }}</p>
            </v-flex>

            <v-flex xs12>
              <p class="font-14 mb-2 white--text graphik-medium">{{ $ml.get('footer_title_itm') }}</p>
            </v-flex>

            <v-flex xs12>
              <p class="font-14 mb-2 white--text graphik-medium">{{ $ml.get('footer_title_id') }}</p>
            </v-flex>

            <v-flex xs12 mt-3>
              <social-media />
            </v-flex>

            <v-flex xs12 my-4>
              <p class="font-12 mb-2 graphik-medium pantene--text uppercase">{{ $ml.get('footer_title_subscribe') }}</p>

              <v-layout row wrap>
                <v-flex xs8 class="pr-2">
                  <v-form v-model="validEmail">
                    <v-text-field
                      class="subscribe-text-field"
                      label="Correo electrónico"
                      v-model="email"
                      required
                      solo
                      type="text"
                      :rules="g_email_rules"
                      :disabled="g_isLoading"
                    ></v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs4 class="pl-2">
                  <v-btn
                    color="pantene"
                    depressed
                    class="normalcase font-15 graphik-bold-italic ma-0 border-radius-5 white--text"
                    @click="suscribe"
                    :disabled="g_isLoading"
                  >{{ $ml.get('general_send') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 md8>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-layout row wrap>
                <template v-for="(item, index) in menu">
                  <v-flex xs12 mb-3 :key="index">
                    <p class="font-12 mb-2 pantene--text graphik-medium uppercase">{{ item.title }}</p>

                    <p class="font-12 graphik-medium white--text">
                      <span 
                        v-for="(sub, idx) in item.submenu"
                        :key="idx + 'menu' + index"
                      >
                        <span v-if="idx !== 0" class="mx-2">•</span>
                        <span @click="sub.action" class="clickable">{{ sub.title }}</span>
                      </span>
                    </p>
                  </v-flex>
                </template>
              </v-layout>
            </v-flex>

            <v-flex xs12 md6 class="mb-5" v-if="$vuetify.breakpoint.mdAndUp">
              <p class="font-12 mb-2 pantene--text graphik-medium uppercase">{{ $ml.get('footer_title_contact') }}</p>

              <p
                class="font-12 graphik-medium clickable mb-2 white--text"
                @click="openLink('tel:8159801244', 'goto_phone')"
              >{{ $ml.get('footer_title_phone') }}</p>

              <p
                class="font-12 graphik-medium clickable mb-2 white--text"
                @click="openLink('tel:8159801252', 'goto_phone')"
              >{{ $ml.get('footer_title_phone_2') }}</p>

              <p
                class="font-12 graphik-medium clickable mb-2 white--text"
                @click="openLink('https://api.whatsapp.com/send?phone=528127251196', 'goto_whatsapp')"
              >{{ $ml.get('footer_title_whatsapp') }}</p>

              <p
                class="font-12 graphik-medium clickable mb-2 white--text"
                @click="openLink('mailto:hola@gorilaseguros.com', 'goto_email')"
              >{{ $ml.get('footer_title_email') }}</p>

              <!-- <p
                class="font-12 graphik-medium mb-2 white--text"
              >{{ $ml.get('footer_title_address') }}</p> -->

              <p
                class="font-12 graphik-medium mb-2 white--text"
              >{{ $ml.get('footer_title_schedule_1') }}</p>

              <p
                class="font-12 graphik-medium mb-2 white--text"
              ><span class="placeholder">{{ $ml.get('footer_title_schedule_placeholder') }}</span>{{ $ml.get('footer_title_schedule_2') }}</p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 class="pt-5 pb-3">
          <v-divider class="white"></v-divider>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm6>
          <p class='font-10 white--text mb-2'> {{ $ml.get('footer_title_disclaimer') }} </p>
          <p class="font-10 white--text">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener" class="pantene--text">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener" class="pantene--text">Terms of Service</a> apply.</p>
        </v-flex>

        <v-flex xs12 sm6>
          <v-img
            :aspect-ratio="16/9"
            max-width="240"
            contain
            position="top center"
            class="clickable"
            :class="{'ml-auto': $vuetify.breakpoint.smAndUp, 'mr-auto': $vuetify.breakpoint.xsOnly,}"
            :src="images.driveop_logo"
            :lazy-src="images.driveop_logo"
            @click="openLink('https://www.driveop.com', 'goto_driveop')"
          ></v-img>
        </v-flex>
      </v-layout>
    </v-container>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-footer>
</template>

<script>
export default {
  components: {
    'social-media': () => import(/* webpackPrefetch: true */ '../components/SocialMedia')
  },
  data () {
    return {
      validEmail: false,
      email: '',
      images: {
        logo: require('@/assets/img/brand/logo-white.png'),
        driveop_logo: require('@/assets/img/sponsors/powered-white.png')
      },
      social_media: [
        {
          image: require('@/assets/img/icons/socialmedia/facebook.png'),
          url: 'https://www.facebook.com/gorilaseguros/',
          event: 'goto_facebook'
        },
        {
          image: require('@/assets/img/icons/socialmedia/instagram.png'),
          url: 'https://www.instagram.com/gorilaseguros/',
          event: 'goto_instagram'
        },
        {
          image: require('@/assets/img/icons/socialmedia/youtube.png'),
          url: 'https://www.youtube.com/c/gorilaseguros',
          event: 'goto_youtube'
        },
        {
          image: require('@/assets/img/icons/socialmedia/linkedin.png'),
          url: 'https://www.linkedin.com/company/gorilaseguros/',
          event: 'goto_linkedin'
        },
        {
          image: require('@/assets/img/icons/socialmedia/twitter.png'),
          url: 'https://twitter.com/cuco_gorila',
          event: 'goto_twitter'
        }
      ],
      menu: [
        {
          title: this.$ml.get('footer_title_company'),
          submenu: [
            {
              title: this.$ml.get('footer_title_about'),
              action: () => this.changeRoute('/nosotros', 'goto_about')
            },
            {
              title: this.$ml.get('footer_title_companies'),
              action: () => this.changeRoute('/aseguradoras', 'goto_companies')
            },
            {
              title: this.$ml.get('footer_title_blog'),
              action: () => this.openLink('http://blog.gorilaseguros.com/', 'goto_blog')
            }
          ]
        },
        {
          title: this.$ml.get('footer_title_products'),
          submenu: [
            {
              title: this.$ml.get('footer_title_car'),
              action: () => this.changeRoute('/auto', 'goto_car')
            },
            {
              title: this.$ml.get('footer_title_house'),
              action: () => this.changeRoute('/hogar', 'goto_house')
            },
            {
              title: this.$ml.get('footer_title_fleet'),
              action: () => this.changeRoute('/flotillas', 'goto_fleet')
            },
            {
              title: this.$ml.get('footer_title_uber'),
              action: () => this.changeRoute('/uber', 'goto_uber')
            },
            {
              title: this.$ml.get('footer_title_life'),
              action: () => this.changeRoute('/vida', 'goto_life')
            }
          ]
        },
        {
          title: this.$ml.get('footer_title_help'),
          submenu: [
            {
              title: this.$ml.get('footer_title_howitworks'),
              action: () => this.changeRoute('/comofunciona', 'goto_howitworks')
            },
            {
              title: this.$ml.get('footer_title_faq'),
              action: () => this.changeRoute('/preguntasfrecuentes', 'goto_faq')
            },
            {
              title: this.$ml.get('footer_title_sinisters'),
              action: () => this.changeRoute('/siniestros', 'goto_sinisters')
            }
          ]
        },
        {
          title: this.$ml.get('footer_title_legal'),
          submenu: [
            {
              title: this.$ml.get('footer_title_privacy'),
              action: () => this.changeRoute('/politicasdeprivacidad', 'goto_privacypolicy')
            },
            {
              title: this.$ml.get('footer_title_terms'),
              action: () => this.changeRoute('/terminosycondiciones', 'goto_terms')
            }
          ]
        }
      ],
      selectedLanguage: ''
    }
  },
  created () {
    this.selectedLanguage = localStorage.language
  },
  methods: {
    suscribe () {
      this.$analytics('subscribe_newsletter')
      this.g_isLoading = true

      if (!this.validEmail) {
        this.g_dialog = {
          title: this.$ml.get('general_error'),
          message: this.$ml.get('rules_invalid_email'),
          show: true
        }

        this.g_isLoading = false
        return
      }

      this.$api.suscribeNewsletter(
        { email: this.email },
        () => {
          this.g_dialog = {
            title: this.$ml.get('general_success'),
            message: this.$ml.get('newsletter_success_message'),
            show: true
          }
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.suscribe()
        }, () => {})
      )
    },
    changeRoute (route, event) {
      if (event) {
        this.$analytics(event)
      }
      this.dialog = false
      this.$router.push(route)
    },
    openLink (url, event) {
      this.$analytics(event)
      window.open(url, '_blank')
    },
    openLinkSocial (social) {
      this.$analytics(social.event)
      window.open(social.url, '_blank')
    }
  }
}
</script>

<style scoped>
</style>
